@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  cursor: pointer;
}

.categoryPicker {
  display: none;
  @media screen and (min-width: vars.$extra-large) {
    cursor: pointer;
    display: block;
    width: 100%;
  }
}

.overflow {
  align-items: center;
  display: flex;
  position: relative;
  column-gap: 10px;
}

.categoryText {
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.arrow {
  width: 12px;
  height: auto;
  transition: transform 0.2s ease-in-out;
  transform: rotate(0deg);

  &Flipped {
    transform: rotate(180deg);
  }
}

.categoryDropdown {
  background-color: colors.$white;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  column-count: 3;
  left: 0;
  border-radius: 4px;
  list-style-type: none;
  padding: 15px;
  position: absolute;
  top: 75px;
  width: 100%;
  z-index: 100;
}

.categoryDropdown:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid colors.$white;
  position: absolute;
  top: -7px;
  left: 75px;

  @media screen and (min-width: vars.$extra-extra-large) {
    left: calc(16.6% - 15px - 38px);
  }
}

.categoryDropdown a {
  align-items: center;
  color: colors.$default-text;
  cursor: pointer;
  display: inline-flex;
  padding: 7.5px 0;
  text-decoration: none;
  width: 100%;
}

.categoryDropdown a:hover {
  color: colors.$primary-color;
}

.categoryDropdown a img {
  margin-right: 13px;
  max-height: 20px;
  max-width: 20px;
}
